import React from 'react'
import useIntl from '../hooks/useIntl'

const BlockReport = ({ data, type }) => {
  const { translate } = useIntl()

  // type = block | unblock
  return (
    <div>
      <p>{translate(`blockReport.${type}.summary`)}</p>
      {
        Object.entries(data).map(([key, value]) => {
          return value.length 
          ? (
              <div key={key}>
              { translate(`blockReport.${type}.${key}`) }
                <ul>
                  { value.map((release, i) => 
                    <li key={i}>{release.container.containerNumber}{key==='errors' && `: ${release.error}`}</li>
                  )}
                </ul>
              </div>
            ) 
          : null
        })
      }
    </div>
  )
}

export default BlockReport
