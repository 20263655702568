/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Route, Redirect, withRouter, useLocation, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import * as Subscriptions from '../util/subscriptions';
import SubscriptionExpired from '../components/SubscriptionExpired';
import { TWO_FACTOR_AUTH } from '../util/twoFactorAuth';

const moment = require('moment');

const ProtectedRoute = ({ component: Component, isProtected, ...rest }) => {
  const { accessToken, user } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const isTwoFactorEmailVerified = window.localStorage.getItem('isTwoFactorEmailVerified') === 'true';

  if(user?.twoFactorAuth === TWO_FACTOR_AUTH.emailEnabled && !isTwoFactorEmailVerified && location.pathname !== '/two-factor/auth/email') {
    history.push('/two-factor/auth/email')
  }

  const isTerminalUser = user && user.organizationRole === "terminal";
  
  // if the user is on its way to the orderform, by all means, let the user go to the orderpage :)
  const isOrderPath = location.pathname === '/order' || location.search.includes('/order')
  const is2FAPath = location.pathname.includes("/two-factor") || location.search.includes('/two-factor')

  const getActiveSubscription = (subscriptions, requiredSubscriptions) => {
    const now = moment();
    return subscriptions.find(s => 
      requiredSubscriptions.includes(s.subscriptionId) && 
      moment(s.startDate).isSameOrBefore(now, 'day') && 
      (s.endDate === null || moment(s.endDate).isSameOrAfter(now, 'day'))
    )
  }

  let walletSubscription, flavorSubscription;
  
  if (user) {
    walletSubscription = getActiveSubscription(user.subscriptions, [Subscriptions.SUBSCR_CLOUD_WALLET, Subscriptions.SUBSCR_COMPANY_WALLET]);
    flavorSubscription = getActiveSubscription(user.subscriptions, [Subscriptions.SUBSCR_PREMIUM, Subscriptions.SUBSCR_PREMIUM_TRIAL, Subscriptions.SUBSCR_NONPAYING, Subscriptions.SUBSCR_API]);
  } else {
	  return <React.Fragment/>
  }
 
  if ((is2FAPath || isOrderPath) || (walletSubscription && flavorSubscription)) {
    return ( 
      <Route
        {...rest}
        render={(props) => {
          if(isProtected && !accessToken) return <Redirect to={`/loading${props.location.search ? props.location.search :'?fwd='+props.location.pathname}`} />
          if(isTerminalUser && props.location.pathname !== '/secure-pickup-containers') return <Redirect to='/secure-pickup-containers' />
          if(!isTerminalUser && props.location.pathname == '/secure-pickup-containers') return <Redirect to='/' />
          return <Component {...props} />
        }}
      />
    )
  } else {

    if (user && !isOrderPath && !walletSubscription) {
      return <SubscriptionExpired notice="No active ID wallet subscription found."/>
    }

    
    if (user && !isOrderPath && !flavorSubscription) {
      return <SubscriptionExpired notice="Your SCR subscription has expired."/>
    }
  }

};

export default withRouter(ProtectedRoute);

