import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TMDataTable from '../../../lib/Table/TMDataTable';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox';
import useIntl from '../../../hooks/useIntl';
import AddTransporterDialog from '../AddTransporterDialog.jsx';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

const ReleasesDrivers = ({ drivers, onSelect, selectFirstRow, refreshData }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const classes = useStyles();
  const { translate } = useIntl();

  const columns = ['name', 'phone', 'email', 'alfapass'].map((column) => translate(`driver.${column}`));

  const data = drivers
    .filter(d => d.isActive)
    .map(({ firstName, lastName, phone, email, alfaPass }) => 
      [`${firstName} ${lastName}`, phone, email, alfaPass]
    );

  const handleDialogClose = async (doRefresh=false) => {
    if (doRefresh) { 
      await refreshData('drivers');
    }
    setOpenAdd(false);
  }

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRowsOnClick: true,
    selectFirstRow,
    sort: false,
    print: false,
    filter: false,
    search: true,
    searchOpen: true,
    download: false,
    viewColumns: false,
    selectableRows: 'single',
    onSelectionChange: ([selected]) => {
      if (selected) {
        const identification = selected.pop();
        onSelect(identification);
      } else {
        onSelect(undefined)
      }
    },
    customToolbar: () => {
      return (<IconButton className={classes.hoverButton} onClick={() => setOpenAdd(true)}><CreateIcon/></IconButton>)
    },
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
  };

  return (
    <div className="no-radius-table">

      <AddTransporterDialog
        type={'driver'} 
        openAdd={openAdd} 
        handleDialogClose={handleDialogClose}
      />

      <TMDataTable columns={columns} data={data} options={options} />
    </div>
  )
};

export default ReleasesDrivers;
